
document.querySelectorAll('.js-svg-load').forEach((el)=>{
    var $this = el
    var ajax = new XMLHttpRequest();
    var svgSrc = $this.getAttribute('data-src-sprite')
    
    ajax.open("GET", svgSrc, true);
    ajax.send();
    ajax.onload = function(e) {
        $this.innerHTML = ajax.responseText;
    }  
})


 